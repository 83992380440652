import { Component, Mixins, Prop } from "vue-property-decorator";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import UIDataTable from "@/components/UIDataTable.vue";
import UIFormRow from "@/components/UIFormRow.vue";
import Flash from "@/store/common/flash";
import AppStatusSearch, {
  ListItem
} from "@/store/app-creation-status-shop/search";
import AppStatusModify from "@/store/app-creation-status-shop/modify";
import AppStatusDetail from "@/store/app-creation-status-shop/detail";
import { DEFAULT_TABLE_OPTIONS, TableOptions } from "@/api/request";
import {
  SearchRequest,
  RegisterRequest
} from "@/api/app-creation-status-shop/request";
import { DetailResult } from "@/api/app-creation-status-shop/response";
import { ServiceProductManagementStorage } from "@/store/service-product-management-storage";

@Component({
  components: { UIDataTable, UIFormRow }
})
export default class NewEditApps extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id!: string | null;

  isLoading = false;
  tableOptions = DEFAULT_TABLE_OPTIONS;
  tableHeaders = [
    { text: "店舗ID", value: "id", sortable: true },
    { text: "店舗名", value: "name", sortable: true },
    { text: "アプリ名", value: "appName", sortable: true },
    { text: "店舗タイプ", value: "shopTypeName", sortable: true },
    { text: "表示", value: "viewFlg", sortable: true },
    { text: "Android", value: "androidFlg", sortable: true },
    { text: "Android状況", value: "appStatusAndroid", sortable: true },
    { text: "iOS", value: "iosFlg", sortable: true },
    { text: "iOS状況", value: "appStatusIos", sortable: true },
    {
      label: "修正",
      text: "",
      value: "edit",
      sortable: false
    }
  ];

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };

  // 検索入力オプション
  inputOptions = {
    searchShopId: null as number | null,
    searchShopName: null as string | null,
    shopType: null as number | null,
    appStatusAndroid: null as number | null,
    appStatusIos: null as number | null
  } as SearchRequest;

  // 登録ダイアログの表示有無
  showRegisterDialog = false;

  detailTableOptions = {} as TableOptions;
  detailTableHeaders = [
    { text: "店舗ID", value: "id", sortable: false },
    { text: "店舗名", value: "name", sortable: false },
    { text: "アプリ名", value: "appName", sortable: false },
    { text: "店舗タイプ", value: "shopTypeName", sortable: false },
    { text: "表示", value: "viewFlgName", sortable: false }
  ];

  registerParams = {} as RegisterRequest | DetailResult;

  shopTypeItems = [
    { id: 0, name: "本店" },
    { id: 1, name: "支店" }
  ];

  androidAppStatusItems = [
    { id: 1, name: "準備中" },
    { id: 2, name: "完了" }
  ];

  iOSAppStatusItems = [
    { id: 1, name: "準備中" },
    { id: 2, name: "申請準備完了" }
  ];

  get tableItems() {
    return AppStatusSearch.getItems;
  }

  get totalCount() {
    return AppStatusSearch.getTotalCount;
  }

  get detailTableItems() {
    return [AppStatusDetail.getDetail];
  }

  get detailTotalCount() {
    return 1;
  }

  // TODO: 残項目
  // 詳細の取得とRegisterダイアログの表示
  // 更新処理

  async created() {
    await AppStatusSearch.restore(this.inputOptions);
    const request = AppStatusSearch.getSearchRequest;
    this.inputOptions = request;
    request.page && (this.tableOptions = request);
    await this.search();
  }

  async detailTableChangeCallback(tableOptions: TableOptions) {}

  async tableChangeCallback(tableOptions: TableOptions) {
    this.tableOptions = tableOptions;
    await this.search();
  }

  async editClickCallback(item: ListItem) {
    await AppStatusDetail.detail({ id: item.id });
    if (!AppStatusDetail.isSuccess) {
      await Flash.setErrorNow({
        message: AppStatusDetail.getMessage,
        showReloadButton: true
      });
      return;
    }
    this.showRegisterDialog = true;
    this.registerParams = AppStatusDetail.getDetail;
  }

  async search() {
    this.isLoading = true;
    await AppStatusSearch.search(this.createSearchRequest());
    if (!AppStatusSearch.isSuccess) {
      await Flash.setErrorNow({
        message: AppStatusSearch.getMessage,
        showReloadButton: true
      });
    }
    this.isLoading = false;
  }

  private createSearchRequest() {
    const request = this.tableOptions as SearchRequest;
    if (this.inputOptions.searchShopId) {
      request.searchShopId = this.inputOptions.searchShopId;
    } else {
      delete request.searchShopId;
    }
    if (this.inputOptions.searchShopName) {
      request.searchShopName = this.inputOptions.searchShopName;
    } else {
      delete request.searchShopName;
    }
    if (this.inputOptions.shopType != null) {
      request.shopType = this.inputOptions.shopType;
    } else {
      delete request.shopType;
    }
    if (this.inputOptions.appStatusAndroid) {
      request.appStatusAndroid = this.inputOptions.appStatusAndroid;
    } else {
      delete request.appStatusAndroid;
    }
    if (this.inputOptions.appStatusIos) {
      request.appStatusIos = this.inputOptions.appStatusIos;
    } else {
      delete request.appStatusIos;
    }
    const storageData = ServiceProductManagementStorage.getSessionStorage();
    request.businessId = storageData.business_id;
    return request;
  }

  async onRegister() {
    await Flash.clear();
    this.showRegisterDialog = false;
    this.isLoading = true;
    await AppStatusModify.register(this.createRegisterRequest());
    if (AppStatusModify.isSuccess) {
      await this.search();
    } else {
      await Flash.setErrorNow({
        message: AppStatusModify.getMessage,
        showReloadButton: false
      });
    }
    this.isLoading = false;
  }

  private createRegisterRequest() {
    const request = {
      id: this.registerParams.id,
      androidFlg: this.registerParams.androidFlg,
      appStatusAndroid: this.registerParams.appStatusAndroid,
      iosFlg: this.registerParams.iosFlg,
      appStatusIos: this.registerParams.appStatusIos
    } as RegisterRequest;
    request.androidApiFcmKey = this.registerParams.androidApiFcmKey;
    request.downloadUrlGoogleplay = this.registerParams.downloadUrlGoogleplay;
    request.downloadUrlAppstore = this.registerParams.downloadUrlAppstore;
    request.iosAppPem = this.registerParams.iosAppPem;
    return request;
  }
}
