import service from "@/api/service";
import {
  SearchRequest,
  DeleteRequest,
  DetailRequest,
  RegisterRequest
} from "@/api/usen-manage/request";
import {
  DetailResponse,
  GetResponse,
  SearchResponse
} from "@/api/usen-manage/response";
import { CommonResponse } from "@/api/response";

/**
 * 店舗一覧・検索APIをコールします。
 *
 * @param searchRequest 店舗検索のリクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post("/search-shop", searchRequest);
  return response.data as SearchResponse;
}

/**
 * 店舗詳細情報取得APIをコールします。
 *
 * @param detailRequest 店舗詳細情報取得のリクエストボディ
 * @return DetailResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post("/detail-shop-usen", detailRequest);
  return response.data as DetailResponse;
}

/**
 * 店舗情報登録更新APIをコールします。
 *
 * @param registerRequest 店舗情報登録更新のリクエストボディ
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post("/regist-shop-usen", registerRequest);
  return response.data as CommonResponse;
}

/**
 * 店舗情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-shop");
  return response.data as GetResponse;
}

/**
 * 店舗情報取得APIをコールします。(本店のみ)
 *
 * @return GetResponse
 */
export async function getMainShop() {
  const response = await service.post("/get-shop",{main_shop_list:1});
  return response.data as GetResponse;
}

/**
 * 店舗情報削除APIをコールします。
 *
 * @param deleteRequest
 * @return CommonResponse
 */
export async function deleteOne(deleteRequest: DeleteRequest) {
  const response = await service.post("/delete-shop", deleteRequest);
  return response.data as CommonResponse;
}
