import service from "@/api/service";
import {
  SearchRequest,
  DetailRequest,
  RegisterRequest
} from "@/api/app-creation-status-shop/request";
import {
  DetailResponse,
  SearchResponse
} from "@/api/app-creation-status-shop/response";
import { CommonResponse } from "@/api/response";

/**
 * アプリ作成状況一覧・検索APIをコールします。
 *
 * @param searchRequest リクエストボディ
 * @return SearchResponse
 */
export async function search(searchRequest: SearchRequest) {
  const response = await service.post(
    "/search-app-creation-status-shop",
    searchRequest
  );
  return response.data as SearchResponse;
}

/**
 * アプリ作成状況詳細APIをコールします。
 *
 * @param detailRequest リクエストボディ
 * @return DetailResponse
 */
export async function detail(detailRequest: DetailRequest) {
  const response = await service.post(
    "/detail-app-creation-status-shop",
    detailRequest
  );
  return response.data as DetailResponse;
}

/**
 * アプリ作成状況登録更新APIをコールします。
 *
 * @param registerRequest リクエストボディ
 * @return CommonResponse
 */
export async function register(registerRequest: RegisterRequest) {
  const response = await service.post(
    "/regist-app-creation-status-shop",
    registerRequest
  );
  return response.data as CommonResponse;
}
