import service from "@/api/service";
import { ConversionResponse } from "@/api/image-conversion/response";
import { ConversionRequest } from "@/api/image-conversion/request";

/**
 * 画像フォーマット変換APIをコールします。
 *
 * @return GetResponse
 */
export async function conversion(request: ConversionRequest) {
  const response = await service.post("/image-conversion", request);
  return response.data as ConversionResponse;
}
