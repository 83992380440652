import {
    getModule,
    Module,
    MutationAction,
    VuexModule
  } from "vuex-module-decorators";
  import store from "@/store";
  import { GetResponse } from "@/api/business-service-authorize-setting/response";
  import * as BusinessServiceAuthorizeSettingAPI from "@/api/business-service-authorize-setting";
  import { isSuccess } from "@/api/response";
  import { GetRequest } from "@/api/business-service-authorize-setting/request";
  
  const MODULE_NAME = "business-service-authorize-setting/get";
  
  /**
   * サービス事業者参照可能項目取得API（/get-business-service-authorize-setting）のレスポンスを管理するストアモジュール
   */
  @Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
  class Get extends VuexModule {
    // state
    getResponse: GetResponse = {} as GetResponse;
  
    // getters
    get isSuccess() {
      return isSuccess(this.getResponse);
    }
  
    get getMessage() {
      return this.getResponse.message;
    }
  
    get getResults() {
      return this.getResponse.results;
    }
    
  get getItems() {
    if (this.getResponse.results) {

      return this.getResponse.results || [];
    } else {
      return [];
    }
  }
  
    // MutationActions
    @MutationAction
    async get(getRequest: GetRequest) {
      const getResponse = await BusinessServiceAuthorizeSettingAPI.get(getRequest);
      return {
        getResponse
      };
    }
  
    @MutationAction
    async clear() {
      return {
        getResponse: {} as GetResponse
      };
    }
  }
  
  
  export default getModule(Get);