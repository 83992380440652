import { Component, Prop, Mixins } from "vue-property-decorator";
import UlContentHeader from "@/components/UlContentHeader.vue";
import UlBreadcrumbs from "@/components/UlBreadcrumbs.vue";
import NewEditBasic from "@/views/usen-manage/NewEditBasic.vue";
import NewEditApps from "@/views/usen-manage/NewEditApps.vue";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import Admin from "@/store/admin/admin";
import ShopDetail from "@/store/shop/detail";
import Flash, {ErrorAlert} from "@/store/common/flash";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import {GetRequest} from "@/api/shop-agreement/request";
import { ServiceProductManagementStorage } from "@/store/service-product-management-storage";



@Component({
  components: {
    UlContentHeader,
    UlBreadcrumbs,
    NewEditBasic,
    NewEditApps
  }
})
export default class NewEdit extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id?: string | null;

  // タイトル
  storageData = ServiceProductManagementStorage.getSessionStorage();
  headingMain = this.storageData.business_name + "管理";
  headingSub = this.storageData.business_name + " Management";
  NEW_BREAD_CRUMBS = [
    { text: "サービスプロダクト管理", disabled: true },
    { text: this.headingMain , disabled: true },
    { text: "店舗一覧", disabled: false, to: "/"+this.storageData.business_name.toLowerCase() + "-management"+"?businessId="+this.storageData.business_id +"&businessName=" +this.storageData.business_name } ,
    { text: "新規作成", disabled: true }
  ];
  
  EDIT_BREAD_CRUMBS = [
    { text: "サービスプロダクト管理", disabled: true },
    { text: this.headingMain , disabled: true },
    { text: "店舗一覧", disabled: false, to: "/"+this.storageData.business_name.toLowerCase()+"-management"+"?businessId="+this.storageData.business_id +"&businessName=" +this.storageData.business_name },
    { text: "編集", disabled: true }
  ];

  // パンくず
  breadCrumbs = this.NEW_BREAD_CRUMBS;

  tabItems = [
    {
      title: "基本情報設定",
      component: "NewEditBasic",
      disabled: false
    },
    {
      title: "作成状況一覧",
      component: "NewEditApps",
      disabled: true
    }
  ];

  // 契約状態
  agreements = {
    ap: false,
    line: false,
    web: false
  };

  // エラーメッセージ
  errorMessage = "";

  get isEdit() {
    return !!this.id;
  }

  get isAdmin() {
    return Admin.isAdmin;
  }

  get isMainShop() {
    return ShopDetail.isMainShop;
  }

  get activeTab() {
    return this.$route.query.tab ? Number(this.$route.query.tab) : 0;
  }

  get shopName() {
    if (ShopDetail.isSuccess) {
      return ShopDetail.getDetail.name;
    }
    return '';
  }

  set activeTab(val) {
    let query = { ...this.$route.query };
    query.tab = String(val);
    this.$router.replace({ query: query }).then();
  }

  /**
   * 契約が Web のみか否かの判定
   * @return true:契約が Web のみ false:契約が Web のみでない
   */
  isWebAgreementOnly(): boolean {
    const result = this.agreements.ap === false && this.agreements.line === false && this.agreements.web === true;
    return result;
  }

  /**
   * 契約が未登録かの判定
   * @return true:契約が 未登録 false:契約が 登録済
   */
  isNotAgreement(): boolean {
    const result = this.agreements.ap === false && this.agreements.line === false && this.agreements.web === false;
    return result;
  }

  /**
   * tabItems の内容を再構築する
   */
  rebuildTabItems() {
    this.tabItems = [];
    this.tabItems.push({
      title: "基本情報設定",
      component: "NewEditBasic",
      disabled: false
    });
    if (this.agreements.ap === true || this.isNotAgreement() === true) {
      this.tabItems.push({
        title: "作成状況一覧",
        component: "NewEditApps",
        disabled: true
      });
    }
  }

  async created() {

    

      // パンくず
    this.breadCrumbs = this.NEW_BREAD_CRUMBS;
    await Admin.restore();
    let shopId = null;
    if (this.isEdit) {
      this.breadCrumbs = this.EDIT_BREAD_CRUMBS;

      await ShopDetail.detail({ id: this.id!! });
      if (!ShopDetail.isSuccess) {
        await Flash.setErrorNow({
          message: ShopDetail.getMessage,
          showReloadButton: true
        });
        return;
      }
      shopId = this.id;
    } else {
      if (this.isAdmin !== true) {
        shopId = Admin.getShopId;
      } else {
        // 登録でスーパーユーザの場合は全て契約
        this.agreements.ap = true;
        this.agreements.line = true;
        this.agreements.web = true;
      }
    }

    if (shopId !== null) {
      // 契約情報を取得する
      const requestAgrement = {
        shopId: shopId
      } as GetRequest;
      await ShopAgreementAPI.get(requestAgrement);
      if (!ShopAgreementAPI.isSuccess) {
        await Flash.setErrorNow({
          message: "",
          showReloadButton: true
        } as ErrorAlert);
        return;
      } else {
        let AgreementItems = ShopAgreementAPI.getagreementsList;
        AgreementItems.forEach((item: { agreementsType: number }) => {
          if (item.agreementsType == 1) {
            this.agreements.ap = true;
          } else if (item.agreementsType == 2) {
            this.agreements.line = true;
          } else if (item.agreementsType == 3) {
            this.agreements.web = true;
          }
        });
        // タブを再構築
        this.rebuildTabItems();
      }
    }

    // 項目名設定・作成状況一覧
    if (this.agreements.ap === true || this.isNotAgreement() === true) {
      this.tabItems[4].disabled = !this.isEdit || !ShopDetail.isMainShop;
      this.tabItems[5].disabled = !Admin.isAdmin;
    } else {
      this.tabItems[3].disabled = !this.isEdit || !ShopDetail.isMainShop;
    }
  }
}
