import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import { GetResponse } from "@/api/image-get/response";
import * as ImageAPI from "@/api/image-get";
import { isSuccess } from "@/api/response";
import { GetRequest } from "@/api/image-get/request";

const MODULE_NAME = "image/get";

/**
 * 画像ダイアログ情報取得API（/image-get）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Get extends VuexModule {
  // state
  getResponse: GetResponse = {} as GetResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.getResponse);
  }

  get getMessage() {
    return this.getResponse.message;
  }

  get getResults() {
    if (this.getResponse.results) {
      return this.getResponse.results;
    } else {
      return null;
    }
  }

  // MutationActions
  @MutationAction
  async get(request: GetRequest) {
    const getResponse = await ImageAPI.get(request);
    return {
      getResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      getResponse: {} as GetResponse
    };
  }
}

export default getModule(Get);
