import {
  getModule,
  Module,
  MutationAction,
  VuexModule
} from "vuex-module-decorators";
import store from "@/store";
import {
  DetailResponse,
  DetailResult
} from "@/api/app-creation-status-shop/response";
import * as AppCreationStatusShopAPI from "@/api/app-creation-status-shop";
import { isSuccess } from "@/api/response";
import { DetailRequest } from "@/api/app-creation-status-shop/request";

const MODULE_NAME = "app-creation-status-shop/detail";

/**
 * アプリ作成状況詳細API（/detail-app-creation-status-shop）のレスポンスを管理するストアモジュール
 */
@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class Detail extends VuexModule {
  // state
  detailResponse: DetailResponse = {} as DetailResponse;

  // getters
  get isSuccess() {
    return isSuccess(this.detailResponse);
  }

  get getMessage() {
    return this.detailResponse.message;
  }

  get getDetail() {
    return this.detailResponse.results || ({} as DetailResult);
  }

  // MutationActions
  @MutationAction
  async detail(detailRequest: DetailRequest) {
    const detailResponse = await AppCreationStatusShopAPI.detail(detailRequest);
    return {
      detailResponse
    };
  }

  @MutationAction
  async clearResponse() {
    return {
      detailResponse: {} as DetailResponse
    };
  }
}

export default getModule(Detail);
