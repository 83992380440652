import { Component, Mixins, Prop } from "vue-property-decorator";
import UIFormRow from "@/components/UIFormRow.vue";
import UlFileRegister from "@/components/UlFileRegister.vue";
import { UlFileRegisiterTabType } from "@/utils/enums";
import RedirectWithAlert from "@/models/mixins/redirect-with-alert";
import MainShopGet from "@/store/main-shop/get";
import StateGet from "@/store/state/get";
import GenreGet from "@/store/genre/get";
import ShopDetail from "@/store/usen-manage/detail";
import ShopModify from "@/store/usen-manage/modify";
import Admin from "@/store/admin/admin";
import Flash, {ErrorAlert} from "@/store/common/flash";
import Loading from "@/store/loading";
import { DetailResult } from "@/api/shop/response";
import { RegisterRequest } from "@/api/shop/request";
import flash from "@/store/common/flash";
import _ from "lodash";
import WindowSizeChecker from "@/utils/window-size-checker";
import ShopAgreementAPI from "@/store/shop-agreement/get";
import {GetRequest, GetRequest as GetAgreementRequest} from "@/api/shop-agreement/request";
import BusinessServiceAuthorizeSetting from "@/store/business-service-authorize-setting/get";
import { GetRequest as BusinessServiceAuthorizeSettingRequest } from "@/api/business-service-authorize-setting/request";
import { ServiceProductManagementStorage } from "@/store/service-product-management-storage";
import ItemNameShopGet from "@/store/item-name-shop/get";
import { GetResult } from "@/api/item-name-shop/response";

const enum NextLabelEnum {
  NEXT = "次へ",
  SAVE = "保存する"
}
//表示項目制御
const ID_SNS = 1;
const ID_UPDATE = 2;
const ID_DIGITAL_TICKET = 3;
const ID_LOGIN = 4;
const ID_MOP = 5;
const ID_Genre = 6;

//事業者ID
const BUSINESS_ID_USEN = 1;
@Component({ components: { UIFormRow, UlFileRegister } })
export default class NewEditBasic extends Mixins(RedirectWithAlert) {
  @Prop({ type: String, required: false })
  id?: string | null;

  @Prop({ type: Object, required: false })
  agreements: {} | undefined;

  initImageUrl = null as string | null;
  allowedTypes = ["image/jpeg", "image/png", "image/gif"];
  appInitImageUrl = null as string | null;
  appAllowedTypes = ["image/png"];

  tabType = UlFileRegisiterTabType.Icon;

  menuProps = {
    closeOnClick: false,
    closeOnContentClick: false,
    disableKeys: true,
    openOnClick: false,
    maxHeight: 304
  };
  /*画面の事業者情報 */
  storageData = ServiceProductManagementStorage.getSessionStorage();

  // 1ページ目の表示かどうか
  isFirstPage = true;

  // 次へ or 保存のラベル
  nextLabel = NextLabelEnum.NEXT;

  // 契約状態（基本情報）
  agreementsBasic = {
    ap: false,
    line: false,
    web: false
  };
  settingBasic = {
    genreFlg:false,
    snsFlg: false,
    updateFlg: false,
    digitalTicketFlg: false,
    loginFlg: false,
    mopFlg: false
  };
  inputParams = {
    businessId: null as number | null,
    /** 店舗ID */
    id: null as string | null,
    /** 2:本部、3:支店 */
    modelType: 2 as 2 | 3,
    /** 本部ID */
    mainShopId: null as string | null,
    /** 店舗名 */
    name: null as string | null,
    /** 店舗名カナ */
    nameKana: null as string | null,
    /** アプリ名 */
    appName: null as string | null,
    /** 店舗説明文 */
    description: null as string | null,
    /** キャッチコピー */
    catchcopy: null as string | null,
    /** 管理画面用のアプリアイコンの編集フラグ */
    appIconEditFlg: 0 as 0 | 1,
    /** 管理画面用のアプリアイコン */
    appIcon: null as string | null,
    /** t_shop_image_histories.id */
    appIconImageId: null as number | null,
    /** 都道府県 */
    state: null as string | null,
    /** 住所1（市区町村）*/
    address1: null as string | null,
    /** 住所2（ビル名など）*/
    address2: null as string | null,
    /** 0:自動、1:手動 */
    geoInputFlg: 0,
    /** 緯度 */
    lat: null as string | null,
    /** 経度 */
    lng: null as string | null,
    /** 電話番号 */
    tel: null as string | null,
    /** 表示フラグ 0:非表示、1:表示*/
    viewFlg: 1,
    /** 強制アップデートフラグ 0:無効、1:有効 */
    forceUpdateFlg: 0,
    /** 法人名 */
    corporateName: null as string | null,
    /** 初期画面変更使用フラグ 0:無効、1:有効 */
    initialTypeUseFlg: 1,
    /** トーク新機能フラグ 0:無効、1:有効 */
    initialTalkFlg: 1,
    /** 予約機能使用フラグ 0:無効、1:有効 */
    bookingFlg: 1,
    /** 2枚以上のスタンプカード設定フラグ 0:無効、1:有効 */
    multiStampFlg: 1,
    /** ポイントカード運用方法の設定 0:無効、1:有効 */
    orgPointFlg: 1,
    /** おもてなしガイドの設定 0:無効、1:有効 */
    guideFlg: 1,
    /** トーク管理側プッシュフラグ 0:無効、1:有効 */
    talkPushFlg: 1,
    /** お友達紹介機能使用フラグ 0:無効、1:有効 */
    introduceType: 1,
    /** デジタルチケット使用フラグ 0:無効、1:有効 */
    paidTicketFlg: 0,
    /** デジタルチケットstripeアカウントID */
    stripeAccountId: null as string | null,
    /** stripeテストフラグ */
    stripeTestFlg: 1,
    /** ApplePay使用フラグ */
    applePayFlg: 0 as number | null,
    /** トークムービーフラグ */
    talkMovieFlg: 1 as number | null,
    /** ログイン機能フラグ 0:無効、1:有効 */
    loginFlg: 0 as number | null,
    /** モバイル＆オーダーペイフラグ 0:無効、1:有効 */
    mobileOrderPayFlg: 0 as number | null,
    /** 責任者名 */
    officerName: null as string | null,
    /** ジャンル */
    genreId: 0,
    /**SNS情報連携フラグ 無効（値=０），有効（値=1）*/
    snsInfoLinkFlg: 0,
    /**SNS投稿連携フラグ 無効（値=０），有効（値=1）*/
    snsPostLinkFlg: 0,

  };

  inputItemParams: GetResult = {
    id: null,
    shopId: Number(this.id),
    cmsShopName: null,
    cmsShopNameKana: null,
    cmsState: null,
    cmsAddress1: null,
    cmsAddress2: null,
    cmsTel: null,
    cmsBusinessHours: null,
    cmsHoliday: null,
    cmsSeat: null,
    cmsParkInfo: null,
    cmsBudget: null,
    cmsReserveUrl: null,
    cmsOther: null,
    cmsInquiryMail: null,
    spShopName: null,
    spAddress: null,
    spTel: null,
    spBusinessHours: null,
    spHoliday: null,
    spSeat: null,
    spParkInfo: null,
    spBudget: null,
    spOther: null,
    spInquiryMail: null
  };

  get isEdit() {
    return !!this.id;
  }

  get isAdmin() {
    return Admin.isAdmin;
  }

  get isTabletAndPC() {
    return WindowSizeChecker.isTabletAndPC(this);
  }

  get mainShopItems() {
    //NEW_DEV-1604 cyber start
    if (!MainShopGet.isSuccess) {
      return [
        {
          id: ShopDetail.getDetail["mainShopId"],
          name: ShopDetail.getDetail["mainShopName"]
        }
      ];
    }
    //NEW_DEV-1604 cyber end
    return MainShopGet.getItems;
  }

  get stateItems() {
    return StateGet.getItems;
  }

  get genreItems() {
    return GenreGet.getItems;
  }

  get isCategorySettable() {
    return this.isEdit || this.inputParams.modelType === 3;
  }

  get loading() {
    return Loading.isLoading;
  }

  /**
   * 契約が Web のみか否かの判定
   * @return true:契約が Web のみ false:契約が Web のみでない
   */
  isWebAgreementOnly(): boolean {
    const result = this.agreementsBasic.ap === false && this.agreementsBasic.line === false && this.agreementsBasic.web === true;
    return result;
  }

  /**
   * 契約が未登録かの判定
   * @return true:契約が 未登録 false:契約が 登録済
   */
  isNotAgreement(): boolean {
    const result = this.agreementsBasic.ap === false && this.agreementsBasic.line === false && this.agreementsBasic.web === false;
    return result;
  }
  /**SNS連携表示制御 */
  get isSNSFlg(): boolean {
    const setting = this.settingBasic.snsFlg === true;
    if(this.storageData.business_id == BUSINESS_ID_USEN){
      return true
    }else{
      return setting;
    }
  }
  // /**強制アップデートフラグ表示制御 */
  get isUpdateFlg(): boolean {
    const setting = this.settingBasic.updateFlg === true;
    const agreement = this.agreementsBasic.ap === true || this.isNotAgreement() === true;
    if(this.storageData.business_id == BUSINESS_ID_USEN){
      return agreement;
    }else{
      return setting && agreement;
    }
  }
  /**デジタルチケット使用フラグ表示制御 */
  get isDigitalTicketFlg(): boolean {
    const setting = this.settingBasic.digitalTicketFlg === true;
    const agreement = this.agreementsBasic.ap === true || this.isNotAgreement() === true;
    if(this.storageData.business_id == BUSINESS_ID_USEN){
      return agreement;
    }else{
      return setting && agreement;
    }
  }
  /**ログイン機能表示制御 */
  get isLoginFlg(): boolean {
    const setting = this.settingBasic.loginFlg === true;
    if(this.storageData.business_id == BUSINESS_ID_USEN){
      return true;
    }else{
      return setting;
    }
  }

  /**モバイルオーダーペイ表示制御 */
  get isMopFlg(): boolean {
    const setting = this.settingBasic.mopFlg === true;
    const agreement = this.agreementsBasic.ap === true || this.isNotAgreement() === true;
    if(this.storageData.business_id == BUSINESS_ID_USEN){
      return agreement;
    }else{
      return setting && agreement;
    }
  }
  /**ジャンル表示制御 */
  get isGenreFlg(): boolean {
    const setting = this.settingBasic.genreFlg === true;
    if(this.storageData.business_id == BUSINESS_ID_USEN){
      return true
    }else{
      return setting;
    }
  }

  async created() {
    await Admin.restore();
    //表示可能項目取得
    const businessServiceAuthorizeSettingRequest = this.createBusinessServiceAuthorizeSettingRequest();
    if (businessServiceAuthorizeSettingRequest != false) {
      await BusinessServiceAuthorizeSetting.get(businessServiceAuthorizeSettingRequest);
      if (!BusinessServiceAuthorizeSetting.isSuccess) {
        await Flash.setErrorNow({
          message: "",
          showReloadButton: true
        } as ErrorAlert);
        return;
      } else {
        const Authorizes = BusinessServiceAuthorizeSetting.getItems;
        Authorizes.forEach((item: { settingId: number }) => {
          if (item.settingId == ID_SNS) {
            this.settingBasic.snsFlg = true;
          } else if (item.settingId == ID_UPDATE) {
            this.settingBasic.updateFlg = true;
          } else if (item.settingId == ID_DIGITAL_TICKET) {
            this.settingBasic.digitalTicketFlg = true;
          } else if (item.settingId == ID_LOGIN) {
            this.settingBasic.loginFlg = true;
          } else if (item.settingId == ID_MOP) {
            this.settingBasic.mopFlg = true;
          } else if (item.settingId == ID_Genre) {
            this.settingBasic.genreFlg = true;
          }
        });
      }
    }

    let getErrorMessage = null as string | null;
    this.storageData = await ServiceProductManagementStorage.getSessionStorage();
    const mainShop = MainShopGet.get(this.storageData.business_id);
    const state = StateGet.get();
    const genre = GenreGet.get();
    await Promise.all([mainShop, state, genre]);
    if (!StateGet.isSuccess) {
      getErrorMessage = StateGet.getMessage;
    }
    if (!GenreGet.isSuccess) {
      getErrorMessage = GenreGet.getMessage;
    }
    if (getErrorMessage) {
      await Flash.setErrorNow({
        message: getErrorMessage,
        showReloadButton: true
      });
      return;
    }

    if (this.isEdit) {
      await ShopDetail.detail({ id: this.id!! });
      if (!ShopDetail.isSuccess) {
        await Flash.setErrorNow({
          message: ShopDetail.getMessage,
          showReloadButton: true
        });
        return;
      }
      this.syncInputParams(ShopDetail.getDetail);
      // 契約情報を取得する
      const requestAgrement = {
        shopId: this.id
      } as GetRequest;
      await ShopAgreementAPI.get(requestAgrement);
      if (!ShopAgreementAPI.isSuccess) {
        await Flash.setErrorNow({
          message: "",
          showReloadButton: true
        } as ErrorAlert);
        return;
      } else {
        const AgreementItems = ShopAgreementAPI.getagreementsList;
        AgreementItems.forEach((item: { agreementsType: number }) => {
          if (item.agreementsType == 1) {
            this.agreementsBasic.ap = true;
          } else if (item.agreementsType == 2) {
            this.agreementsBasic.line = true;
          } else if (item.agreementsType == 3) {
            this.agreementsBasic.web = true;
          }
        });
      }
    }

    if (this.inputParams.modelType === 2) {
      if (this.agreementsBasic.ap === true || this.isNotAgreement() === true) {
        this.nextLabel = NextLabelEnum.NEXT;
      } else {
        this.nextLabel = NextLabelEnum.SAVE;
      }
    } else {
      this.nextLabel = NextLabelEnum.SAVE;
    }

    await ItemNameShopGet.get({ id: Number(this.id) });
    if (!ItemNameShopGet.isSuccess) {
      await Flash.setErrorNow({
        message: ItemNameShopGet.getMessage,
        showReloadButton: true
      });
      return;
    }
    this.inputItemParams = ItemNameShopGet.getResult;
  }

  setAppIconFile(data: string, id: number | null) {
    this.inputParams.appIconEditFlg = 1;
    this.inputParams.appIcon = data;
    this.inputParams.appIconImageId = id;
  }

  unsetAppIconFile() {
    this.inputParams.appIconEditFlg = 1;
    this.inputParams.appIcon = null;
    this.inputParams.appIconImageId = null;
  }

  changeShopType(val: string) {
    if (this.inputParams.modelType === 2) {
      if (!this.isEdit) {
        this.inputParams.isCategory = 0;
      }
      if (this.isWebAgreementOnly() === true) {
        this.nextLabel = NextLabelEnum.SAVE;
      } else {
        this.nextLabel = NextLabelEnum.NEXT;
      }
    } else {
      this.nextLabel = NextLabelEnum.SAVE;
    }
  }

  showReportMailAdd(index: number) {
    const reportMailLength = this.inputParams.reportMailObjs.length;
    return reportMailLength === index + 1 && reportMailLength < 5;
  }

  addReportMail(index: number) {
    this.inputParams.reportMailObjs.splice(index + 1, 0, { value: null });
  }

  removeReportMail(index: number) {
    this.inputParams.reportMailObjs.splice(index, 1);
    if (this.inputParams.reportMailObjs.length <= 0) {
      this.inputParams.reportMailObjs.push({ value: null });
    }
  }

  goBack() {
    if (this.isFirstPage) {
      this.$router.back();
    } else {
      window.scrollTo(0, 0);
      this.nextLabel = NextLabelEnum.NEXT;
      this.isFirstPage = true;
    }
  }

  async goForward() {
    if (this.nextLabel === NextLabelEnum.SAVE) {
      await flash.clear();
      await ShopModify.register(this.createRegisterRequest());
      
      if (ShopModify.isSuccess) {
        this.storageData = ServiceProductManagementStorage.getSessionStorage();
        await this.redirectWithSuccessAlert(
          "店舗を保存しました。",
          "/" + this.storageData.business_name.toLowerCase() + "-management",{businessId:this.storageData.business_id,businessName:this.storageData.business_name}
        );
      } else {
        await flash.setErrorNow({
          message: ShopModify.getMessage,
          showReloadButton: false
        });
        var res = ShopModify.commonResponse;
        if(res.errors) {
          console.log(JSON.stringify(res.errors));
          await flash.setErrorNow({
            message: ShopModify.getMessage,
            showReloadButton: false,
            messageSuccessBasic: res.messageSuccessBasic ? res.messageSuccessBasic : ''
          });
        }
      }
    } else {
      window.scrollTo(0, 0);
      this.nextLabel = NextLabelEnum.SAVE;
      this.isFirstPage = false;
    }
  }

  private syncInputParams(shopDetail: DetailResult) {
    this.inputParams = { ...this.inputParams, ...shopDetail };
    if (shopDetail.appIcon) {
      this.appInitImageUrl = shopDetail.appIcon;
    }
  }

  private createRegisterRequest() {
    const registerRequest = _.cloneDeep(this.inputParams);

    if (registerRequest.geoInputFlg === 0) {
      delete registerRequest.lat;
      delete registerRequest.lng;
    }

    if (
      registerRequest.appIconImageId != null ||
      registerRequest.appIconEditFlg === 0
    ) {
      delete registerRequest.appIcon;
    }
    /**事業者IDを追加 */
    registerRequest.businessId = this.storageData.business_id;
    return registerRequest as RegisterRequest;
  }
  /**
 * OEM事業者の参照可能な項目を取得するためのリクエストを作成する。
 * 
 */
  private createBusinessServiceAuthorizeSettingRequest() {
    this.storageData = ServiceProductManagementStorage.getSessionStorage();
    if (this.storageData.business_id == 1) {
      return false;
    }
    return {
      business_id: this.storageData.business_id,//ログインアカウントの事業者ID(1：USEN、2：Ligliy)
      menu_group_id: 30//現在の画面の機能グループID
    } as BusinessServiceAuthorizeSettingRequest;
  }
}
