import service from "@/api/service";
import { GetResponse } from "@/api/genre/response";

/**
 * 都道府県情報取得APIをコールします。
 *
 * @return GetResponse
 */
export async function get() {
  const response = await service.post("/get-genre");
  return response.data as GetResponse;
}
